<template>
  <div>
    <help-button v-if="helpUrls.traffic_dsp_help" />
    <div class="text-center my-8 font-weight-bold">
      {{ $t('providers.choose_plan') }}
    </div>
    <div class="d-flex justify-center align-stretch flex-wrap">
      <v-card
        v-for="(promoPlan, index) of availablePlans"
        :key="promoPlan.id"
        width="272px"
        :class="[
          'card-plan d-flex flex-column align-center py-5 px-6',
          index === 1 ? 'mx-4' : 'my-4'
        ]"
      >
        <div :class="['mb-10', { 'd-flex align-center': !isMdAndUp }]">
          <div
            class="text-h4 font-weight-bold text-center"
            :style="`color: ${promoPlan.textColor}`"
          >
            {{ promoPlan.transformMaxRequestsPerSecond }}
          </div>
          <div :class="['text-body-2 secondary-darken--text', { 'ml-4': !isMdAndUp }]">
            {{ $t('providers.request_per_second') }}
          </div>
        </div>
        <div class="d-flex align-center flex-row flex-md-column mb-4">
          <icon-chart-success
            class="primary--text mr-2 mr-md-0 mb-md-4"
            :icon-size="index === 1 ? 38 : 22"
            :progress="index"
          />
          <div v-html="promoPlan.pickCurrentLocale(locale).name" />
        </div>
        <div class="mb-10" v-html="promoPlan.pickCurrentLocale(locale).description" />
        <v-spacer />
        <div class="global-full-width text-center">
          <span class="text-h6 font-weight-bold  mb-1">{{ `$${promoPlan.price}` }}</span>
          <c-btn
            block
            color="primary"
            :label="$t('providers.buy_now')"
            @click="onBuyButtonClick(promoPlan.id)"
          />
        </div>
      </v-card>
    </div>
    <subscribe-dialog
      :is-shown="modalIsShown"
      :is-loading="subscriptionIsLoading"
      @close-dialog="modalIsShown = false"
      @confirm="subscribePlan()"
    />
    <low-funds-dialog
      :is-shown="fundsDialogIsShown"
      :is-loading="subscriptionIsLoading"
      @close-dialog="fundsDialogIsShown = false"
      @add-funds="$router.push({ name: routeNames.ADD_FUNDS })"
    />
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import IconChartSuccess from '@clickadilla/components/icons/IconChartSuccess.vue'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { trafficProviderRepository } from '@/services/repository-factory.js'
  import handleErrors from '@/services/handleErrors.js'
  import SubscribeDialog from './SubscribeDialog.vue'
  import LowFundsDialog from './LowFundsDialog.vue'
  import ProviderPlan from '@/services/classes/ProviderPlan.js'
  import HelpButton from '../../HelpButton.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'ProvidersPromoPlans',
    components: {
      CBtn,
      IconChartSuccess,
      SubscribeDialog,
      LowFundsDialog,
      HelpButton
    },
    data() {
      return {
        routeNames,
        subscriptionIsLoading: false,
        availablePlans: [],
        selectedPlan: 0,
        modalIsShown: false,
        fundsDialogIsShown: false,
        headers: [
          {
            value: 'price'
          }
        ]
      }
    },
    computed: {
      ...mapState('settings', ['locale']),
      ...mapGetters('settings', ['helpUrls']),
      isMdAndUp() {
        return this.$vuetify.breakpoint.mdAndUp
      }
    },
    created() {
      this.fetchPromoPlans()
    },
    methods: {
      ...mapActions('personal', ['fetchUserProfile']),
      async subscribePlan() {
        this.subscriptionIsLoading = true
        try {
          await trafficProviderRepository.subscribe(this.selectedPlan)
          await this.fetchUserProfile()
        } catch (error) {
          this.modalIsShown = false
          if (error.response.status === 422) {
            this.fundsDialogIsShown = true
            this.subscriptionIsLoading = false
            return
          }
          handleErrors(error)
        }
        this.subscriptionIsLoading = false
      },
      onBuyButtonClick(planId) {
        this.modalIsShown = true
        this.selectedPlan = planId
      },
      async fetchPromoPlans() {
        try {
          const requestBody = {
            headers: this.headers,
            options: { sortBy: ['price'] }
          }
          const response = await trafficProviderRepository.plans(requestBody)
          this.availablePlans = response?.map((plan) => new ProviderPlan(plan) ?? [])
        } catch (error) {
          handleErrors(error)
        }
      }
    }
  }
</script>
