<template>
  <v-dialog :value="isShown" max-width="342" @input="closeDialog()">
    <v-card class="d-flex flex-column align-end pa-3">
      <v-icon size="18" @click="closeDialog()">
        $close-outlined
      </v-icon>
      <div class="pa-7">
        <p class="text-center text-subtitle-2">
          {{ $t('providers.change_plan') }}
        </p>
      </div>
      <c-btn
        block
        color="primary"
        :label="$t('providers.i_confirm')"
        :loading="isLoading"
        @click="$emit('confirm')"
      />
    </v-card>
  </v-dialog>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'UnsubscribeDialog',
    components: { CBtn },
    props: {
      isShown: {
        type: Boolean,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
        subscriptionIsLoading: false
      }
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog')
      }
    }
  }
</script>
