<template>
  <v-card :loading="isLoading" elevation="0">
    <v-card-title class="white--text black font-weight-medium pa-2 pl-6">
      {{ $t('providers.traffic_dsp') }}
      <v-spacer />
      <template v-if="userHasProviderSubscription">
        <c-btn
          class="mb-2 mb-md-0"
          :block="isSmAndDown"
          color="primary"
          :to="{ name: 'CreateProvider' }"
          :label="$t('providers.create_provider')"
        />
        <v-chip
          class="ml-md-2 py-4"
          :color="userTariffPlan.plan.textColor"
          text-color="white"
          label
          outlined
        >
          <span class="mr-1">{{ $t('providers.pricing_plan') }}:</span>
          <span :style="`color: ${userTariffPlan.plan.textColor}`" v-html="planTranslation.name" />
        </v-chip>
        <v-btn color="primary" icon @click="unsubscribeDialogIsShown = true">
          <v-icon color="primary">
            $config
          </v-icon>
        </v-btn>
      </template>
    </v-card-title>
    <template v-if="!fetchIsLoading">
      <providers-tables v-if="userHasProviderSubscription" />
      <providers-promo v-else />
    </template>
    <unsubscribe-dialog
      :is-shown="unsubscribeDialogIsShown"
      :is-loading="isLoading"
      @confirm="deleteUserSubscription()"
      @close-dialog="unsubscribeDialogIsShown = false"
    />
  </v-card>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import handleErrors from '@/services/handleErrors.js'
  import ProvidersPromo from './ProvidersPromo/Index.vue'
  import { trafficProviderRepository } from '@/services/repository-factory.js'
  import UnsubscribeDialog from './UnsubscribeDialog.vue'
  import ProvidersTables from './ProvidersTables/index.vue'

  export default {
    name: 'Providers',
    components: {
      CBtn, ProvidersPromo, UnsubscribeDialog, ProvidersTables
    },
    data() {
      return {
        unsubscribeDialogIsShown: false,
        isLoading: false
      }
    },
    computed: {
      ...mapState('personal', ['trafficProviderSubscription', 'fetchIsLoading']),
      ...mapState('settings', ['locale']),
      ...mapGetters('personal', ['userHasProviderSubscription']),
      isSmAndDown() {
        return this.$vuetify.breakpoint.smAndDown
      },
      userTariffPlan() {
        return this.trafficProviderSubscription
      },
      planTranslation() {
        return this.trafficProviderSubscription.plan.pickCurrentLocale(this.locale)
      }
    },
    async created() {
      await this.fetchUserProfile()
    },
    methods: {
      ...mapActions('personal', ['fetchUserProfile']),
      async deleteUserSubscription() {
        this.isLoading = true
        const { id } = this.trafficProviderSubscription
        try {
          await trafficProviderRepository.delete(id)
        } catch (error) {
          handleErrors(error)
        }
        await this.fetchUserProfile()
        this.isLoading = false
        this.unsubscribeDialogIsShown = false
      }
    }
  }
</script>
